import { useState, useEffect } from "react"
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { transformValue } from "@utils/transformValue";

// import Banner from "assets/png/Banner ayude.png"
import Banner from "assets/png/BANNER SITE_NUMERO.png"
// import Banner from "assets/png/BANNER SITE 2.png"
import BannerEvent from "assets/png/BANNER EVENTO.png";
import BannerEventSmall from "assets/png/BANNER EVENTO SMALL.png";
import { colors, theme } from "theme";
import Icons from "utils/icons";
import { SalesTableProps } from "types/proposals";
import RemoteServices from "service";
import useAuth from "context/auth";
import { formatCurrency } from "@utils/format";
import Breadcrumb from "components/breadcrumbs";
import useProposal from "context/proposal";
import ValueInvalid from "components/valueInvalid";
import Toast from "components/@ui/toast";
import { awaitTime } from "utils/awaitTime";
import { useLocation } from "react-router";
import { DialogTitle } from "@material-ui/core";
import useMediasQuerys from "hooks/mediasQuery";
import QRCodeSimulator from "components/qrCodeSimulator";
import TooltipUI from "components/@ui/Tooltip";
import { Link } from "react-router-dom";

const minValue = 300
const maxValue = 5000

const Lend = () => {

    const { matchesMaxMobile, matchesOtherBanner } = useMediasQuerys()

    const {
        search
    } = useLocation()

    const {
        User
    } = useAuth();

    const {
        parcels,
        setParcels,
        setValuesList,
        valueProposal,
        saleTables,
        setSaleTables,
        showForm,
        typeSimulator,
        ClearProposal
    } = useProposal();

    const [LoadingSaleTables, setLoadingSaleTables] = useState<boolean>(false);

    const [openAlertValueInvalid, setOpenAlertValueInvalid] = useState(false)

    const [modalMaintenance, setModalMaintenance] = useState(false)


    useEffect(() => {
        if (search.slice(1, search.length) === 'noreload') {
            return
        }
        ClearProposal()
    }, [])

    useEffect(() => {
        if (User) {
            const array: number[] | string[] = ["0"]
            for (let value = minValue; value <= maxValue; value += 10) {
                array.push(formatCurrency(value))
            }
            setValuesList(array)
        }
    }, [User])


    useEffect(() => {
        if (parcels && (valueProposal && valueProposal !== "0")) {
            getLinks(Number(parcels), transformValue(valueProposal))

        }
    }, [parcels, valueProposal, typeSimulator])



    const sendToShow = (id: string, placement: ScrollLogicalPosition = 'start') => {
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView({
                block: placement ?? 'center',
                behavior: "smooth"
            });
        }
    }

    async function getLinks(parcels: number, valueProposal: number) {
        setLoadingSaleTables(true)
        RemoteServices.proposals.GetLinks({ parcels, net_amount: valueProposal }).then(({ response }) => {
            if (response?.saleTables) {
                const firstFilter = response.saleTables.filter((item: SalesTableProps) => Number(item.sale_table.replace('on-', '')) <= 15)
                setSaleTables(firstFilter)
            }
        }).catch(async (e) => {
            await awaitTime(3)
            Toast({
                type: 'error'
            }, 'Falha ao buscar links')
            return getLinks(parcels, valueProposal)

        }).finally(() => {
            setLoadingSaleTables(false)
        })
    }


    useEffect(() => {
        if (showForm) {
            sendToShow("parcels", "start")
            if (saleTables?.length > 0) {
                sendToShow("tables-on", "start")
            }
            if (LoadingSaleTables) {
                sendToShow("tables-on-loading", "start")
            }
        }
    }, [showForm, saleTables, LoadingSaleTables])


    return (
        <>
            <Grid
                xs={12}>
                {/* <TooltipUI title="Clique no Banner para acessar nosso grupo de WhatsApp e participar de nosso treinamento gratuito!" followCursor>
                    <Link to={"https://chat.whatsapp.com/F5s8xvMj7Tj1JpSVCzY6kz"} target="_blank">
                        <img
                            src={matchesOtherBanner ? BannerEventSmall : BannerEvent}
                            style={{ maxHeight: '100%', padding: '0px 10px', cursor: 'pointer' }}
                            width={"100%"}
                            alt="BannerEvent" />
                    </Link>
                </TooltipUI> */}
                <img
                    src={Banner}
                    style={{ maxHeight: '100%', padding: matchesMaxMobile ? 0 : '0px 10px', cursor: 'pointer' }}
                    width={"100%"}
                    alt="BannerEvent" />
                <Grid
                    xs={12}
                    mt={2}
                    p={2}>
                    {
                        !matchesMaxMobile &&
                        <Typography
                            textTransform={"uppercase"}
                            color={colors.grey400}
                            fontWeight={"bold"}>
                            <Breadcrumb />
                        </Typography>
                    }

                    <Grid px={2}>
                        <Grid
                            container
                            gap={2}
                            py={2}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                            // mt={-8}
                            mb={8}
                        >
                            <Grid
                                item
                                gap={2}
                                py={2}
                                container
                                flexDirection={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                {
                                    !matchesMaxMobile &&
                                    <>
                                        <Grid maxWidth={'auto'} container gap={2} flexDirection={"row"}>
                                            <img
                                                src={Icons.IconQRCODE}
                                                alt="icon"
                                                width={30}
                                                style={{ filter: 'brightness(0)' }}
                                            />
                                            <Typography
                                                textTransform={"uppercase"}
                                                color={colors.black}
                                                variant="h5"
                                                fontWeight={"bold"}>link/qr code <u>autoempréstimo</u>
                                            </Typography>
                                        </Grid>
                                        {/* <Grid maxWidth={'auto'} container gap={2} flexDirection={"row"} mt={2}>
                                            <img
                                                src={Icons.IconPUBLICIDADES}
                                                alt="icon"
                                                width={20}
                                                style={{ filter: 'brightness(0)' }}
                                            />
                                            <Typography
                                                textTransform={"uppercase"}
                                                color={colors.black}
                                                variant="body1"
                                                fontWeight={"bold"}>Sugestões de <a href="/app/publicidades" style={{
                                                    textDecoration: 'none',
                                                    color: theme().palette.primary.main
                                                }}>publicidades</a>
                                            </Typography>
                                        </Grid> */}
                                    </>

                                }
                                {/* <Grid>
                                    <Grid
                                        gap={2}
                                        py={2}
                                        container
                                        direction={"row"}

                                    >
                                        <a target="_blank" href="/app/emprestar/tutorial?id=1">
                                            <img
                                                src={WithLinkImagem}
                                                style={{
                                                    width: '250px',
                                                    height: '130px',
                                                    cursor: 'pointer'
                                                }}

                                            />
                                        </a>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid mt={-4} container flexDirection={"column"} xs={12} gap={4}>
                            <QRCodeSimulator
                                LoadingSaleTables={LoadingSaleTables}
                                changeParcel={(parcelSelect) => {
                                    setParcels(parcelSelect !== parcels ? parcelSelect : null)
                                    setSaleTables([])
                                }}
                                parcels={parcels}
                                showTablesSimulator={true}
                                saleTables={saleTables} />
                        </Grid>
                    </Grid>
                </Grid>
                <ValueInvalid
                    openAlertValueInvalid={openAlertValueInvalid}
                    onClose={() => {
                        setOpenAlertValueInvalid(false)
                    }}
                />
            </Grid>
            <Dialog open={modalMaintenance}>
                <DialogTitle>
                    <Typography variant="h4" textAlign={"center"}>Manutenção</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography padding={"20px 30px"}>Prezado cliente, neste momento o banco está em manutenção, já estamos trabalhando para voltar à normalidade o mais breve possível.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setModalMaintenance(false)}>Entendi</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Lend;